export const COMPONENT_GLOBAL_CONFIG = {
	CANVAS_ENTRY: {
		HOVER_OVER_TOOLBAR: {
			ENTER_NEXT_DELAY: 300,
			ENTER_DELAY: 300,
		},
	},
	TOOLTIP: {
		ENTER_NEXT_DELAY: 300,
		ENTER_DELAY: 300,
	},
	MAX_INSIGHT_RATING_STAR_COUNT: 5
};

export const ROLES = {
	TEAM_MEMBER: "teammember",
	COHORT_ADMINISTRATOR: "cohortadministrator",
	INSTRUCTOR: "instructor",
	MENTOR: "mentor",
};

export const APP_RETRY_TIMER = {
	INTERVAL: 60 * 1000,
	RETRY_COUNT: Infinity,
}

export const WHITELIST_ACTIVITY_FEED_TYPES = [
	"HypothesisLink",
	"Hypothesis",
	"EntryContentChange",
	"HypothesisStateChange",
	"Finding",
];

export const KEY_CODES = {
	ENTER: 13,
}

export const ROLE_COLORS = {
	TeamMember: "#2e7d32",
	Mentor: "#1565c0",
	Observer: "#565656",
	Instructor: "#ffee58",
	CohortAdministrator: "#c62828",
	Administrator: "#c62828",
	SystemAdministrator: "#565656",
};

export const DEFAULT_INSIGHT_TAGS_BY_USER_ROLE = {
	TEAM_MEMBER: "General Insight",
	INSTRUCTOR: "Feedback",
};

export const APP_NOTIFICATION_COMPONENT_STATIC_TEXTS = {
	ICON_BUTTON: {
		TOOLTIP: "Show All Notifications",
	},
	POPOVER: {
		TITLE: "Notifications",
	},
	MARK_AS_READ_BUTTON: {
		TOOLTIP: "Mark as read",
	},
	MARK_AS_UNREAD_BUTTON: {
		TOOLTIP: "Mark as unread",
	},
	MARK_ALL_AS_READ_BUTTON: {
		LABEL: "Mark all as read",
	},
	SHOW_ONLY_UNREAD_SWITCH: {
		LABEL: "Show unread only",
	},
};

export const NOTIFICATION_TYPES = {
	INSIGHT: "insight",
	COMMENT: "bmc-comment",
	EXPORT: "export",
};

export const ACTIVITY_FEED_TYPES = {
	FINDING: "Finding",
	HYPOTHESIS: "Hypothesis",
	HYPOTHESIS_STATE_CHANGE: "HypothesisStateChange",
	INTERVIEW: "Interview",
	HYPOTHESIS_LINK: "HypothesisLink",
	ENTRY_CONTENT_CHANGE: "EntryContentChange",
	HYPOTHESIS_BULK_DELETE: "HypothesisBulkDelete",
};

export const NOTIFICATION_UNREAD_SWITCH_VALUES = {
	ALL: "All",
	UNREAD: "Unread",
};

export const BMC_GEN_SWITCH_VALUES = {
	CANVAS: "Canvas",
	ACTIVITY: "Activity",
};

export const INTERVIEW_NAVIGATION_TABS = {
	PUBLISHED: "published",
	DRAFT: "draft",
}

export const APP_ELEMENT_DIMENSIONS = {
	CANVAS_TOOLBAR: {
		HEIGHT: "48px",
	},
	CHAT_PANEL: {
		WIDTH: "400px",
	},
	ANALYTICS: {
		COHORT_STATS: {
			OVERVIEW_PANEL: {
				WIDTH: "225px",
			},
		},
	},
	SCORING: {
		STATS: {
			HEIGHT: "260px",
		},
		OVERVIEW_STAT: {
			WIDTH: "256px",
		}
	}
};

export const UPLOAD_STATUS_MENU_ITEM_TEXTS = {
	RETRY_BUTTON: {
		TOOLTIP: "Retry",
	},
	CANCEL_BUTTON: {
		TOOLTIP: "Cancel Upload",
	},
};

export const TEMPLATE_EDITOR_MODAL_TEXTS = {
	MODAL_HEADER: {
		CLOSE_BUTTON: {
			EMPTY_TEMPLATE_NAME: {
				TOOLTIP: "Please, provide a template title",
			},
			NORMAL: {
				TOOLTIP: "Close",
			},
		},
		BACK_BUTTON: {
			NORMAL: {
				TOOLTIP: "Return to Template List",
			},
		},
	},
};

export const LOADING_DATA_TEXT = "Loading data, please wait...";

export const NOTIFICATION_OVERVIEW_TEXTS = {
	SEGMENT_SECTION_TITLE: "Segment Name",
	COMMENT_SECTION_TITLE: "Comment",
	INTERVIEW_SECTION_TITLE: "Interview",
	QUOTE_SECTION_TITLE: "Quote",
};

export const TEAM_DETAILS_POPOVER_CHART_TEXTS = {
	INTERVIEW_INSIGHT_CHART: {
		TITLE: "Interview and Insight Metrics",
	},
	INTERVIEW_INSIGHT_CHART_BY_MEMBER: {
		TITLE: "Interviews and Insights By Team Member",
	},
	HYPOTHESIS_METRICS_CHART: {
		TITLE: "Hypothesis Metrics",
	},
};

export const TEMPLATE_CONTENT_CHANGE_INDICATOR_SHOW_TIME = 3000; // in miliseconds

export const NOT_AVAILABLE_TEXT = "Not Available";

export const PROD_URLS = [
	"innovationwithin.com",
	"app.innovationwithin.services",
	"v3preview.innovationwithin.services",
];

export const DEFAULT_SCORING_RATING = 3;

export const STAR_RATINGS_DISTRIBUTION = {
	Problem: 1,
	"Cause for Concern": 2,
	"Acceptable/Okay": 3,
	Good: 4,
	"Very Good/Excellent": 5,
};

export const PUBNUB_MESSAGE_TYPE = {
	COMMENT: "comment",
	NOTIFICATION: "notification",
	CHAT: "chat",
	CHAT_UPDATE: "chat update",
	CHAT_DELETE: "chat delete",
	TEMPLATE: "template",
	AI_TEMPLATE: "ai:template",
	AI_CANVAS: "ai:canvas",
	DEEPGRAM: "deepgram",
	AUDIO_ENCODING: "audio_encoding",
	ENTRY: 'entry',
};

export const ALLOWED_PUBUNB_MESSAGE_TYPE = [
	PUBNUB_MESSAGE_TYPE.TEMPLATE,
	PUBNUB_MESSAGE_TYPE.AI_CANVAS,
	PUBNUB_MESSAGE_TYPE.AI_TEMPLATE,
	PUBNUB_MESSAGE_TYPE.DEEPGRAM,
	PUBNUB_MESSAGE_TYPE.AUDIO_ENCODING,
	PUBNUB_MESSAGE_TYPE.CHAT,
	PUBNUB_MESSAGE_TYPE.CHAT_UPDATE,
	PUBNUB_MESSAGE_TYPE.CHAT_DELETE,
	PUBNUB_MESSAGE_TYPE.COMMENT,
	PUBNUB_MESSAGE_TYPE.NOTIFICATION,
	PUBNUB_MESSAGE_TYPE.ENTRY,
];

export const PUBNUB_MESSAGE_ACTION = {
	CREATE: "create",
	UPDATE: "update",
	DELETE: "delete",
	GENERATE: "generate",
	BULK_DELETE: "bulk_delete",
};

export const INTERVIEW_PAGE_WIDTH_LIMITS = {
	ORG_TREE: 1700,
	INTERVIEW_LIST: 1400,
}

export const ARTIFACT_TYPES = {
	INTERVIEW_NOTE: "interview_note",
	TRANSCRIPT: "transcript",
	TEMPLATE: "template",
	CANVAS_NOTE: "canvas_note",
};

export const REMIRROR_BLOT_TYPES = {
	INSIGHT: "insight",
}

export const INSIGHT_ACTIONS = {
	CREATE: "create",
	DELETE: "delete",
}

export const INTERVIEW_ASSEMBLY_TYPES = {
  VIDEO: "video",
  AUDIO: "audio",
};

export const REALTIME_TEMPLATE_DELETE_MESSAGE = "Template you are editing is deleted.";

export const AI_GENERATION_PROGRESS = {
	NOT_STARTED: "not_started",
	IN_PROGRESS: "in_progress",
	DONE: "done",
}

export const AI_GENERATION_COMPLETION_STATUS = {
	NO_STATUS: "no_status",
	SUCCESSFULL: "successfull",
	FAILED: "failed",
};

export const EDITOR_MAP = {
	QUILL: "react-quill",
	MATERIAL: "material",
};

export const KEY_PARTNERS = "Key Partners";
export const KEY_ACTIVITIES = "Key Activities";
export const KEY_RESOURCES = "Key Resources";
export const VALUE_PROPOSITIONS = "Value Propositions";
export const CUSTOMER_RELATIONSHIPS = "Customer Relationships";
export const CHANNELS = "Channels";
export const CUSTOMER_SEGMENTS = "Customer Segments";
export const COST_STRUCTURE = "Cost Structure";
export const REVENUE_STREAMS = "Revenue Streams";

export const PROBLEM = "Problem";
export const SOLUTION = "Solution";
export const KEY_METRICS = "Key Metrics";
export const UNFAIR_ADVANTAGE = "Unfair Advantage";
export const EXISTING_ALTERNATIVES = "Existing Alternatives";
export const HIGH_LEVEL_CONCEPT = "High-Level Concept";
export const EARLY_ADOPTERS = "Early Adopters";

export const LEGACY_SORT_KEYS_BY_CATEGORY = {
	BMC: {
		[KEY_PARTNERS]: 1,
		[KEY_ACTIVITIES]: 2,
		[KEY_RESOURCES]: 3,
		[VALUE_PROPOSITIONS]: 4,
		[CUSTOMER_RELATIONSHIPS]: 5,
		[CHANNELS]: 6,
		[CUSTOMER_SEGMENTS]: 7,
		[COST_STRUCTURE]: 8,
		[REVENUE_STREAMS]: 9,
	},
	LEAN: {
		[PROBLEM]: 1,
		[SOLUTION]: 2,
		[KEY_METRICS]: 3,
		[VALUE_PROPOSITIONS]: 4,
		[UNFAIR_ADVANTAGE]: 5,
		[CHANNELS]: 6,
		[CUSTOMER_SEGMENTS]: 7,
		[COST_STRUCTURE]: 8,
		[REVENUE_STREAMS]: 9,
		[EXISTING_ALTERNATIVES]: 10,
		[EARLY_ADOPTERS]: 11,
		[HIGH_LEVEL_CONCEPT]: 12,
	},
};

export const CALENDAR_COMPONENT_STATIC_TEXTS = {
	POPOVER: {
		TITLE: "Calendar",
	},
	ConnectCalendar:{
		INFO_TEXT: "Your upcoming interviews and meetings on your calendar show up here.",
		CONNECT_BUTTON: "Connect Calendar",
	},
	ZoomConnectedMessage:{
		SUCCESS_MESSAGE: "Success! Your Zoom account is now connected.",
		INFO_TEXT: "This setup lets you seamlessly manage recordings and access transcripts right from the app!",
		GOT_IT_BUTTON:"Got it"
	},
	CalendarConnectedMessage:{
		SUCCESS_MESSAGE: "Google Calendar connected successfully!",
		INFO_TEXT: "You can now view your upcoming meetings directly in the app. To unlock more features like automatic meeting transcription, connect your Zoom account.",
		LATER_BUTTON:"Later",
		CONNECT_BUTTON:"Connect Zoom"
	}
}

export const MATERIAL_CANVAS_NOTE_EDITOR_ALERT_TYPE = {
	DELETE: "delete",
	GENERATE: "generate",
}

export const DASHJS_EVENTS = {
	PLAYBACK_STARTED: "playbackStarted",
	PLAYBACK_PAUSED: "playbackPaused",
	PLAYBACK_ENDED: "playbackEnded",
	PLAYBACK_PLAYING: "playbackPlaying",
	CAN_PLAY: "canPlay",
}

