import { WHITELIST_ACTIVITY_FEED_TYPES } from "../constants";

// Only certain activity feed actions are considered valid on the BMC
// these would be any activity feed types that effect what content
// is actually *displayed* on the BMC.  Specifically for scrubbing through 
// the activity feed history
const isActivityFeedEntryValid = (activityFeed) => {
	const { name } = activityFeed;

	return WHITELIST_ACTIVITY_FEED_TYPES.includes(name);
};

export default isActivityFeedEntryValid;
