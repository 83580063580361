/// <reference path='./index.d.ts' />

import { ActionHandlers, ActionSignature, Store } from '../../../lib/pork/index';
import { some } from 'lodash';

type State = FindingTypes;

// TODO: Move this to its own folder findingType
export class FindingTypeStore extends Store<State> {
    static namespace = 'findingTypes';

    initialState: State = {}

    actionHandlers: ActionHandlers = {
        clearPrivateData: (state: State, action: ActionSignature<ClearPrivateDataPayload>) => this.initialState,
        load
    }
}

/** replaces entire state */
function load(state: State, action: ActionSignature<State>): State {
    if (action.error) return state;

    return action.payload;
}
