/// <reference path='./index.d.ts' />
/**
 * TODO: we can probably not have this store at all, the discovery store handles all actions
 * keeping around for now...
 */
import { ActionHandlers, ActionSignature, Store } from '../../../lib/pork/index';
import { assign, omit, indexBy, mapValues, reduce, sample } from 'lodash';

import {processFinding} from './util';

type State = Findings;

export class FindingStore extends Store<State> {
    static namespace = 'findings';

    initialState: State = {};

    deserialize(state: Object): State {
        return <State>mapValues(omit(state, 'placeholder'), (finding: Object) => processFinding(finding));
    }

    actionHandlers: ActionHandlers = {
        clearPrivateData, load, create, change, remove, internallyUpdate
    }
}

function clearPrivateData(state: State, action: ActionSignature<ClearPrivateDataPayload>) {
    return this.initialState;
}

function load(state: State, action: ActionSignature<State>) {
    if (action.error) return state;

    if (Object.keys(action.payload).length === 0) return state;

    // MP-716: In order to prevent stale, deleted findings from hanging around, we
    // need to remove all findings with the given artifactID. Load
    // will never be called with a single finding, only for all findings
    // for an artifact.
    const stateSansCurrentArtifactFindings = reduce(state, (newState, finding) => {
        if (finding.artifactID === sample(action.payload).artifactID) return newState;
        newState[finding.id] = finding;
        return newState;
    }, {});

    return assign({}, stateSansCurrentArtifactFindings, action.payload);
}

//manage findings
function create(state: State, action: ActionSignature<Finding>) {
    if (action.error) return state;

    state = <State>omit(state, 'placeholder');

    return changeFinding(state, action.payload);
}

function change(state: State, action: ActionSignature<Finding>) {
    if (action.error) return state;

    return changeFinding(state, state[action.payload.id], action.payload);
}

function remove(state: State, action: ActionSignature<{interviewID: string, findingID: string}>) {
    if (action.error) return state;

    return <State>omit(state, action.payload.findingID);
}

function changeFinding(state: State, finding: Finding | FindingReference, data?: Object): State {
    return <State>assign({}, state, {
        [finding.id]: assign({}, finding, data)
    });
}

// Update only in this store, this method does not come with API updates as well.
function internallyUpdate(state: State, action): State {
    const update = action.payload;
    const oldFinding = state[update.findingID];

    return assign({}, state, {
        [update.findingID]: assign({}, oldFinding, update)
    });
}
