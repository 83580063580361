
import {Request} from 'superagent';

import { CallbackHandler, ApiBase } from '../base';
import {DiscoveryApi} from '../discovery-api';

class AdminApi extends DiscoveryApi {
    routePrefix = 'admin';

    attemptOldInviteRemoval(email: string, callback: CallbackHandler<any>) {
        return this.post('/purge_invite_data', {email}, callback);
    }
}

export let adminAPI = new AdminApi();
