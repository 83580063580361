/// <reference path='./index.d.ts' />

import {CallbackHandler} from '../base';
import {DiscoveryApi} from '../discovery-api';

class SignupApi extends DiscoveryApi {
    routePrefix = 'user';

    signup(email: string, accessCode: string, callback: CallbackHandler<api.GenericResponse>): void {
        let request: SignupAPI.Request = {
            email, accessCode
        }

        this.request('post', '/signup', request, null, callback);
    }

    invite(emailAdresses: string[], callback: CallbackHandler<api.GenericResponse>): void {
        this.request('post', '/signup/invite', {
            emailAdresses
        }, null, callback);
    }

    verify(invitationCode: string, callback: CallbackHandler<api.GenericResponse>): void {
        this.request('get', '/signup/verify', {
            invitationCode
        }, null, callback);
    }

    complete(signupAccessToken: string, name: string, username: string, password: string, invitationCode: string, callback: CallbackHandler<api.GenericResponse>): void {
        this.upload('/signup/complete', {
            accessToken: signupAccessToken,
            name,
            username,
            password,
            invitationCode
        }, null, callback);
    }
}

export let signupAPI = new SignupApi();