/// <reference path="./index.d.ts" />

import { ActionHandlers, ActionSignature, Store } from '../../../lib/pork';
import {assign, merge, omit, keyBy, indexOf, map, clone, each} from 'lodash';
import { find } from 'lodash';
import { canvasAPI } from 'app/api';

import moment from 'moment';

export class CanvasStore extends Store<ICanvas> {
    static namespace = 'canvas';

    initialState = {
        selectedAction: '-1',
        selectedWeek: '-1',
        selectedHypothesis: undefined,
    };

    // TODO pare
    serialize(state: ICanvas) {
        return state;
    }

    actionHandlers: ActionHandlers = { // ICanvas | CanvasEntry
        clearPrivateData: (state: ICanvas, action: ActionSignature<void>) => this.initialState,

        getCanvas,
        getCanvasBase,
        getCanvasStates,
        getCanvasEntries,
        reorderEntries,
        clearCanvasName,

        createEntry,
        updateEntryCategory,
        deleteEntry,
        setCanvasPayload,
        selectLockedHypothesis,
        selectActionIndex,
        selectWeekIndex
    }
}

function setCanvasPayload(state: ICanvas, action: ActionSignature<ICanvas>) {
    let canvas = clone(state);

    let categoryBuffer = {};

    for (let i = 0; i < action.payload.categories.length; i++) {
        categoryBuffer[action.payload.categories[i].id] = action.payload.categories[i];
    }

    let report = {
        ...canvas,
        ...action.payload,
        categories: categoryBuffer
    }

    return <ICanvas>assign({}, state, report)
}

function clearCanvasName(state: ICanvas,  action: ActionSignature<ICanvas>) {
    let newState = clone(state, true);

    newState.name = undefined;

    return newState;
}

function getCanvasStates(state: ICanvas,  action: ActionSignature<ICanvas>) {
    if (action.error) return state;

    let newCanvas = clone(action.payload);
    let canvas = clone(state);

    canvas.states = keyBy(newCanvas.states, 'name');

    return <ICanvas>assign({}, state, canvas)
}

function getCanvasEntries(state: ICanvas,  action: ActionSignature<ICanvas>) {
    if (action.error) return state;

    let newCanvas = clone(action.payload);
    let canvas = clone(state);

    if (canvas === undefined) {
        return <ICanvas>assign({}, state, canvas)
    }

    if (canvas.categories === undefined) {
        return <ICanvas>assign({}, state, canvas)
    }

    canvas.categories = keyBy(map(canvas.categories, category => {
        const foundCategory = find(newCanvas.categories, cat => {
            if (cat === undefined || category === undefined) {
                return false;
            }

            return cat.id === category.id
        });

        if (foundCategory) {
            const newEntries = foundCategory.entries;
            
            if (newEntries && newEntries.length) {
                return <CanvasCategory>assign({}, category, {entries: map(newEntries, (entry: CanvasEntry) => entry.id)})
            }
        }

        return category;
    }), 'id');

    return <ICanvas>assign({}, state, canvas)
}

function getCanvasBase(state: ICanvas,  action: ActionSignature<ICanvas>) {
    if (action.error) return state;

    let canvas = clone(action.payload);

    if (canvas.categories) {
        canvas.categories = keyBy(map(canvas.categories, category => {
            return category;
        }), 'id');
    }

    return <ICanvas>assign({}, state, canvas)
}

function getCanvas(state: ICanvas,  action: ActionSignature<ICanvas>) {
    if (action.error) return state;

    let canvas = clone(action.payload);

    if (canvas.states) canvas.states = keyBy(canvas.states, 'name');

    if (canvas.categories) {
        canvas.categories = keyBy(map(canvas.categories, category => {
            if (category.entries && category.entries.length) {
                return <CanvasCategory>assign({}, category, {entries: map(category.entries, (entry: CanvasEntry) => entry.id)})
            }

            return category;
        }), 'id');
    }

    return <ICanvas>assign({}, state, canvas)
}

function reorderEntries(state: ICanvas,  action: ActionSignature<SortedEntries>) {
    if (action.error) return state;

    let {categoryID, entryIDs} = action.payload,
        category = clone(state.categories[categoryID]);

    category.entries = entryIDs;

    let newState = clone(state, true);
    newState.categories[categoryID] = category;
    return newState;
}

function addOrRemoveEntry(entryID: string, immutableEntries: string[], add: boolean = true) {
    let entries = [];

    if (!immutableEntries) immutableEntries = [];

    immutableEntries.map( eID => {
        if ((!add && eID !== entryID) || add) entries.push(eID);
    });

    if (add) entries.push(entryID);

    return entries;
}

function createEntry(state: ICanvas, action: ActionSignature<CanvasEntry>) {
    if (action.payload.categoryID) {
        let category = clone(state.categories[action.payload.categoryID]);

        category.entries = addOrRemoveEntry(action.payload.id, category.entries);

        let newState = clone(state, true);

        newState.categories = {
            ...newState.categories
        }

        newState.categories[category.id] = category;

        return newState;
    }

    return state;
}

function deleteEntry(state: ICanvas, action: ActionSignature<DeleteEntry>) {
    let {entryID, categoryID} = action.payload;
    if (entryID && categoryID) {
        let category = clone(state.categories[categoryID]);

        category.entries = addOrRemoveEntry(entryID, category.entries, false);

        let newState = clone(state, true);
        newState.categories[categoryID] = category;
        return newState;
    }

    return state;
}

function updateEntryCategory(state: ICanvas, action: ActionSignature<CanvasEntry>) {
    let {id, categoryID} = action.payload;
    if (id && categoryID) {
        let category = clone(state.categories[categoryID]);

        category.entries = addOrRemoveEntry(id, category.entries);

        let oldCategoryObj = getCategoryByEntryId(state, id);

        let newState = clone(state, true);
        newState.categories[categoryID] = category;
        if (oldCategoryObj) {
            newState.categories[oldCategoryObj.id] = oldCategoryObj;
        }
        return newState;
    }

    return state;
}

function getCategoryByEntryId(state: ICanvas, entryID: string) {
    if (state.categories) {
        for (let categoryID in state.categories) {
            let category = state.categories[categoryID],
                hasEntry = indexOf(category.entries, entryID);

            if (hasEntry >= 0) {
                let cat = clone(category);
                cat.entries = addOrRemoveEntry(entryID, cat.entries, false);

                return  cat;
            }
        }
    }

    return null;
}

function selectWeekIndex(state: ICanvas, payload) {
    const selectedWeek = payload.payload;
    return assign({}, state, { selectedWeek });
}

function selectActionIndex(state: ICanvas, payload) {
    const selectedAction = payload.payload;
    return assign({}, state, { selectedAction, selectedWeek: '-1' });
}

function selectLockedHypothesis(state: ICanvas, payload) {
    const selectedHypothesis = payload.payload;
    return assign({}, state, { selectedHypothesis });
}

