import {without} from 'lodash';
import { lodashContains } from '../app/util/lodash_migration';

interface IListener<T> {
    (arg: T): void;
}

export class PubSub<T> {
    listeners: IListener<T>[] = [];
    
    subscribe(listener: IListener<T>): void {
        let {listeners} = this;
        if (!lodashContains(listeners, listener)) this.listeners.push(listener);
    }
    
    unsubscribe(listener: IListener<T>): void {
        without(this.listeners, listener);
    }
    
    publish(info: T): void {
        let {listeners} = this;
        
        listeners.map( listener => {
            listener.call(this, info);
        });
    }
    
}
