/// <reference path='./index.d.ts' />

import { dispatcher, dispatch } from '../../../lib/pork';
import { flatten, assign, mapValues, indexBy, map } from 'lodash';
import { lodashKeyBy } from '../../util/lodash_migration';
import { findWhere } from '../../util';
import { parallel } from 'async';
import { unparse } from 'papaparse';

import { processMetadata, constructAction } from '../util';
import { processInterview } from './util';
import { selectInterview, selectInterviewAction } from '../app/actions';
import { loadNoteData, loadNoteDataSync } from '../note/actions';
import { loadTranscriptsData } from '../transcript/actions';
import * as FindingActions from '../finding/actions';

import billingState from '../billing';

import { InterviewStore } from './index';
import { FindingTypeStore } from './finding-types';
import { FindingStore } from '../finding';
import { AppStore } from '../app';
import { processFinding } from '../finding/util';

import { interviewApi } from '../../api';
import { transcriptApi } from '../../api';
import * as canvasActions from '../canvas/actions';
import { userHasRole } from '../../util';

//import FileSaver from 'filesaver.js'

const interviewNamespace   = InterviewStore.namespace;
const findingTypeNamespace = FindingTypeStore.namespace;
const findingsNamespace    = FindingStore.namespace;
const appNamespace         = AppStore.namespace;

// convenience helpers for action type string construction
export const interviewActionType = (subName: string): string => {
    return `${interviewNamespace}.${subName}`;
};

const findingTypesActionType = (subName: string): string => {
    return `${findingTypeNamespace}.${subName}`;
};

const findingsActionType = (subName: string): string => {
    return `${findingsNamespace}.${subName}`;
};

const appActionType = (subName: string): string => {
    return `${appNamespace}.${subName}`;
};

const getTranscriptDataByInterview = (interview: Interview): string => {
    // if there's no transcript for the interview, we don't want to
    // try to process it all below, so we'll return the empty string
    // for ease of serialization.
    if (!interview.transcripts) return '';

    const dispatcherState = dispatcher.getState();
    const transcript      = dispatcherState.transcript[interview.id];

    // also, if for some reason we don't have the transcript for a specific
    // interview, maybe it hasn't been fetched yet, we'll let the user
    // know
    if (!transcript)
        return 'Innovation Within: Transcript was not yet fetched at time of export.';

    return transcript
        [interview.transcripts[0].id]
        .transcript;
};

const getNotesTextByInterview = (interview: Interview): string => {
    const noteText = dispatcher.getState().note[interview.id];
    const note     = interview.notes;

    if (note && !noteText && noteText !== '')
        return 'Innovation Within: Note was not yet fetched at time of export.';

    return noteText;
}

// take an interview, return object of findings, each with .created/.modified
// dates converted to JS Date
function processFindings(interview: api.Interview): { [key: string]: Finding } {
    const findingsById = map(interview.findings, (finding) => {
        // findings with their date string converted to JS Date objects
        return processFinding(<api.Finding>finding);
    });

    return lodashKeyBy(findingsById, 'id');
}

// fetch all interviews, findingTypes, notes (corresponds to 'load' reducer)
// TODO: This is a mega overloaded function. There's duplicate functionality
// in here from the transcript actions and from notes actions. Those are
// for single fetches, this is for multiple, but the logic can be combined
// by the singles returning an action instead of
export function loadDiscoveryDetails(groupID: string, callback?: (interviews) => void) {
    // canvas details are needed for hypothesis links
    canvasActions.getCanvas(groupID);

    interviewApi.details(groupID, (error, response) => {
        if (error) return dispatch(constructAction(interviewActionType('load'), error, true));

        const { interviews, findingTypes } = response.body;

        // MP-764: We need to make sure we only populate a list if the user is still on the same list.
        // This is necessary in the case of rapid team switching, a common use case by instructors.
        let payloadGroupID: string;
        let currentGroupID: string;
        try {
            payloadGroupID = interviews[0] && interviews[0].groupID;
            currentGroupID = dispatcher.getState().team.id;
        } catch (e) {
            // This means there's no team or a problem with interviews.
            // Either way, we don't want to render these interviews.
            console.error('there was a problem mining information from GET /discovery:', e);
            return;
        }

        // Note -- if there are no interviews, we can safely add everything.
        if (interviews.length && payloadGroupID !== currentGroupID) return;

        const interviewsById:   Interviews   = mapValues(lodashKeyBy(interviews, 'id'), processInterview);
        const findingTypesById: FindingTypes = lodashKeyBy(findingTypes, 'id');

        const findingsById: Findings = interviews.reduce((acc, interview) => {
            return assign(acc, processFindings(interview));
        }, {});

        const interviewsAction   = constructAction<Interviews>(interviewActionType('load'), interviewsById);
        const findingTypesAction = constructAction<FindingTypes>(findingTypesActionType('load'), findingTypesById);
        const findingsAction     = constructAction<Findings>(findingsActionType('load'), findingsById);

        dispatch<Interviews|FindingTypes|Findings>(interviewsAction, findingTypesAction, findingsAction);

        // Run our callback if it's given
        if (callback) {
            callback(interviews);
        }
    });
}

export function loadInterviewSync(interviewID: string, callback: (data) => void): void {
    if (interviewID === 'placeholder') return;

    interviewApi.getInterview(interviewID, (error, response) => {
        const interview: api.InterviewResponse = response.body;

        const findingsAction  = constructAction<Findings>(findingsActionType('load'), processFindings(interview));
        const interviewAction = constructAction<Interview>(interviewActionType('change'), processInterview(interview));

        dispatch<any>(findingsAction, interviewAction);

        const { transcripts, notes } = interview;

        if (notes !== undefined) {
            loadNoteDataSync(interviewID, notes.dataURL, (resp) => {
                callback(resp);
            });
        }
        else {
            callback({})
        }
    });
}

// helper function to create all the actions of loading an individual interview deeply.
// This will help us to iterate through each interview, loading it deeply, for actions
// such as interview export, without making a succession of synchronous dispatches.
function createLoadInterviewActions(interviewID: string, callback: (error, actions?) => void): void {
    // no need to fetch what exists only locally
    if (interviewID === 'placeholder') return;

    const { user, team } = dispatcher.getState();

    interviewApi.getInterview(interviewID, (error, response) => {
        if (error) return callback(error);

        // we'll fill this up with all the actions and return em.
        let actions = [];

        const interview: api.InterviewResponse = response.body;

        const findingsAction  = constructAction<Findings>(findingsActionType('load'), processFindings(interview));
        const interviewAction = constructAction<Interview>(interviewActionType('change'), processInterview(interview));

        actions.push(findingsAction, interviewAction);

        const { transcripts, notes } = interview;

        // working on fetching the transcripts and notes in parallel, then will push those
        // actions into the actions array above and then give them to the top lvl callback
        // for loadInterview to call.
        // const fetchTranscriptsDataTask = (asyncCB) => {
        //     if (transcripts) {
        //         if (transcripts.length > 0) {
        //             // navigator.serviceWorker.controller.postMessage({
        //             //     cmd: 'update_hash',
        //             //     artifactID: transcripts[0].id,
        //             //     hash: transcripts[0].versionCache
        //             // });
        //         }

        //         return loadTranscriptsData(transcripts, (error, actions) => {
        //             asyncCB(error, actions);
        //         });
        //     }

        //     else return asyncCB(null, []);
        // }

        // const fetchNotesDataTask = (asyncCB) => {
        //     if (notes && notes.dataURL) {
        //          // Alert our service worker to the change
        //         loadNoteDataSync(interviewID, notes.dataURL, (resp) => {
        //             console.log(resp);
        //         });
        //     }
        //     else return asyncCB(null, []);
        // }

        // // fetch the transcript and notes data
        // parallel([fetchTranscriptsDataTask, fetchNotesDataTask], (error, dataActions) => {
        //     // pass the actions to the original callback
        //     actions = actions.concat(flatten(dataActions)); // transcripts returns an array
        //     callback(error, actions);
        // });

        // this signifies the interview is newly created in the API and the user
        // has interview write perms TODO: this doesn't really belong here.
        if (!notes && !transcripts && userHasRole('TeamMember', user, team)) {
            createNotes(interviewID, '');
        }
    });
}

export function revertInterviewTranscription(interviewID: string, callback?: Function) {
    transcriptApi.revert(interviewID, () => {
        if (callback) {
            callback();
        }
    });
}

// fetch an interview deeply, meaning all of the interview, the artifacts,
// the artifact data, and the findings.
export function loadInterview(interviewID: string, callback?: Function) {

    createLoadInterviewActions(interviewID, (error, actions) => {
        if (error) return dispatch(constructAction(interviewActionType('change'), error, true));

        dispatch(...actions);

        if (callback) callback();
    })
}

// TODO: determine if this path makes sense (causes the UI to have trouble reasoning), if not, do we create and use optimism? depends on discussion about ui flow for creating and recording an interview
export function createPlaceholder(interviewDetails?: api.Interview) {
    dispatch(constructAction<Interview>(`${interviewNamespace}.create`, <Interview>assign({
        id: 'placeholder',
        created: new Date(),
        isPlaceholder: true
    }, {
        ...interviewDetails,
        status: 'draft'
    })));

    selectInterview('placeholder');
}

// create interview
export function create(groupID: string, interviewDetails: api.Interview, cb?: any) {
    interviewApi.createInterview(groupID, interviewDetails, (error, response) => {
        if (error) return dispatch(constructAction(`${interviewNamespace}.create`, error, true));

        dispatch(
            selectInterviewAction(response.body.id), // TODO: let the view do this
            constructAction<Interview>(`${interviewNamespace}.create`, processInterview(response.body))
        );

        // Now that the interview is created, tell the API to make a notes artifact for it
        createNotes(response.body.id, '', () => {
            if(cb) {
                cb(response.body.id);
            }
        });
    });
}

// update interview
export function update(interviewID: string, interviewDetails: api.Interview) {
    interviewApi.updateInterview(interviewID, interviewDetails, (error, response) => {
        if (error) return dispatch(constructAction(`${interviewNamespace}.update`, error, true));

        dispatch(constructAction<Interview>(`${interviewNamespace}.update`, processInterview(response.body)));
    });
}

// remove interview
export function remove(interviewID: string) {
    if (interviewID === 'placeholder') {
        return dispatch(constructAction(`${interviewNamespace}.remove`, interviewID));
    }

    interviewApi.deleteInterview(interviewID, (error, response) => {
        if (error) return dispatch(constructAction(`${interviewNamespace}.remove`, error, true));

        dispatch(constructAction<string>(`${interviewNamespace}.remove`, interviewID));
    });
}

function createNotes(interviewID: string, note: string, callback?: ErrorCallback) {
    const type = `${interviewNamespace}.createNotes`;



    interviewApi.createNotes(interviewID, note, (error, response) => {
        const notes = response.body;

        if (error) {
            if (callback) callback(error);
            return dispatch(constructAction(type, error, true));
        }

        const actionData = { interviewID, notes };

        dispatch(constructAction(type, actionData));

        // now that there's a notes artifact, let's get its empty data
        loadNoteData(interviewID, notes.dataURL, (error, action) => {
            dispatch(action);

            if (callback) callback();
        });
    });
}

export function recieveJobData(data: {}) {
    // A job update has been found
    // route it accordingly.

    // An interview has finished uploading
    if (data.status === "created") {
        // Let our store know the interview is ready
        const type = `${interviewNamespace}.finishedUploading`;

        dispatch(constructAction(type, data.interviewID));
    }

    // A transcription has had it's audio finish encoding
    if (data.status === "ready_to_transcribe") {
        // Dispatch a matching action
        const type = `${interviewNamespace}.finishRecording`;

        let payload: AudioArtifactPayload = {
            id: data.interviewID,
            audio: processMetadata<api.AudioArtifact, AudioArtifact>(data.interview.audio)
        };

        // NOTE: We're having issues with the audio not being replicated in time
        // in attempt to cut back on the occurence of the 404 occuring let's delay
        // before this action is constructed

        const secondsToWait = 10;

        setTimeout(() => {
            dispatch(constructAction(type, payload));
        }, secondsToWait * 1000);
    }

    // A full transcription pass has completed
    // we need to re-load the data for the interview we're currently monitoring
    if (data.status === "COMPLETED") {
        createLoadInterviewActions(data.interviewID, (error, actions) => {
            if (error) return dispatch(constructAction(interviewActionType('change'), error, true));
    
            dispatch(...actions);
        });
    }
}

export function recieveJobUpdate(data) {
    // A full transcription pass has completed
    // we need to re-load the data for the interview we're currently monitoring
    if (data.status === "COMPLETED") {
        createLoadInterviewActions(data.interviewID, (error, actions) => {
            if (error) return dispatch(constructAction(interviewActionType('change'), error, true));
    
            dispatch(...actions);
        });
    }
}

export function sendAudioData(interviewID: string, audioLocation: string, callback: (error: any) => void) {
    interviewApi.uploadAudioName(interviewID, audioLocation, (error, response) => {
        recieveJobData(response.body);

        callback(undefined);

        dispatch(constructAction<InterviewReference>(`${interviewNamespace}.updateRecording`, { id: interviewID }));
    });
}

export function deleteArtifact(interviewID: string, artifactID: string) {
    interviewApi.deleteArtifact(artifactID, (error, response) => {
        loadInterview(interviewID);
    });
}

export function createBlankTranscription(interviewID: string) {
    // Make a call to create a new fresh blank transcription
    interviewApi.createTranscription(interviewID, (error, response) => {
        // Call to re-load the interview
        loadInterview(interviewID);
    });
}

export function transcribeInterview(interviewID: string, method: string, speakerCount: string) {
    const type = `${interviewNamespace}.transcribeInterview`;

    interviewApi.transcribe(interviewID, method, speakerCount, (error, response) => {
        if (error) return dispatch(constructAction(type, error, true));

        let payload: {interviewID: string, transcript: api.TranscriptArtifact } = {
            interviewID,
            transcript: response.body
        };

        dispatch(constructAction(type, payload));

        billingState.checkCredit();
    });
}

export function downloadInterviewsCSV(groupID: string) {
    // modify state for loading indicator
    dispatch(constructAction(appActionType('exportInterviewsStart')));

    interviewApi.exportInterviews(groupID, (error, response) => {
        if (error) {
            // for discovery (interview) store to handle the error
            dispatch(constructAction(interviewActionType('exportInterviews'), error, true));

            // for app state store to show that the export loading is done and allow them
            // to click it again.
            return dispatch(constructAction(appActionType('exportInterviewsEnd')));
        }

        const csv = response.text;
        const blob: Blob = new Blob([csv]);
        const filename: string = 'interviews.csv';

        // modify state for loading indicator
        dispatch(constructAction(appActionType('exportInterviewsEnd')));

        //FileSaver.saveAs(blob, filename);
    });
}
