/// <reference path='./index.d.ts' />

import {merge} from 'lodash';

import {CallbackHandler} from '../base';
import {DiscoveryApi} from '../discovery-api';

class CanvasApi extends DiscoveryApi {
    routePrefix = 'canvas';

    getCanvas(groupID: string, callback: CallbackHandler<api.CanvasResponse>) {
        this.get('', { groupID }, callback);
    }

    getCanvasEntries(groupID: string, callback: CallbackHandler<api.CanvasResponse>) {
        this.get('/entries', { groupID }, callback);
    }

    getCanvasStates(groupID: string, callback: CallbackHandler<api.CanvasResponse>) {
        this.get('/states', { groupID }, callback);
    }

    getCanvasBase(groupID: string, callback: CallbackHandler<api.CanvasResponse>) {
        this.get('/base', { groupID }, callback);
    }

    putCanvas(groupID: string, data: CanvasAPI.PUTDetails, callback: CallbackHandler<api.CanvasResponse>) {
        this.put('', merge({groupID}, data), callback);
    }

    reorderEntries(groupID: string, entryIDs: string, callback: CallbackHandler<string>) {
        this.put('/reorder', { groupID, entryIDs }, callback);
    }

    createEntry(modifier: string, groupID: string, categoryID: string, entry: string, stateID: string, linkedHypothesis: string, callback: CallbackHandler<api.Entry>) {
        this.post('/entry',{
            groupID,
            categoryID,
            entry,
            stateID,
            modifier,
            linkedHypothesis
        } , null, callback);
    }

    updateEntry(entryID: string, entry: string, version:number, callback: CallbackHandler<api.Entry>) {
        this.put('/entry', { entryID, entry, latestVersion: version }, callback);
    }

    updateEntryState(entryID: string, stateID: string, callback: CallbackHandler<api.Entry>) {
        this.put('/entry/state', { entryID, stateID }, callback);
    }

    updateEntryCategory(entryID: string, categoryID: string, callback: CallbackHandler<api.Entry>) {
        this.put('/entry/category', { entryID, categoryID }, callback);
    }

    getEntry(entryID: string, callback: CallbackHandler<api.Entry>) {
        this.get('/entry', { entryID }, callback);
    }

    deleteEntry(entryID: string, callback: CallbackHandler<string>) {
        this.delete('/entry', { entryID }, callback);
    }

    linkHypotheses(entryID: string, targetEntryID: string, callback: CallbackHandler<any>) {
        this.put('/entry/link', { entryID, targetEntryID }, callback);
    }

    unLinkHypotheses(entryID: string, targetEntryID: string, callback: CallbackHandler<any>) {
        this.delete('/entry/link', { entryID, targetEntryID }, callback);
    }

    supportFinding(entryID: string, findingID: string, supports: boolean, callback: CallbackHandler<api.Entry>) {
        let request: CanvasAPI.SupportFindingRequest = {
            entryID, findingID, supports
        };

        this.put('/entry/finding', request, callback);
    }

    removeFindingSupport(entryID: string, findingID: string, callback: CallbackHandler<api.GenericResponse>) {
        let request: CanvasAPI.RemoveFindingSupportRequest = {
            entryID, findingID
        };

        this.delete('/entry/finding', request, callback);
    }
}

export let canvasAPI = new CanvasApi();
if (process.env.NEXT_PUBLIC_DEVELOPMENT) ((typeof window !== "undefined") ? window : {})['canvasAPI'] = canvasAPI;
