import { ActionSignature, ActionHandlers } from './actions';
import { Store } from './store';
//import Immutable from 'seamless-immutable';
import { each, mapValues, has, without, assign, reduceRight, pick } from 'lodash';
import { lodashContains } from '../../../app/util/lodash_migration';

interface IHandler { (...actions: ActionSignature<any>[]): any; }

interface IMiddleware {
    (next: IHandler): IHandler;
}

interface IStores {
    [key: string]: Store<any>
}

interface IState {
    [key: string]: any
}

export class Dispatcher {
    state:  IState  = {};
    stores: IStores = {};

    private subscriptions: Array<Function> = [];

    getState()        { return this.state; }
    getInitialState() { return mapValues(this.stores, (store) => store.initialState); }

    use(middleware: IMiddleware): Dispatcher {
        this.dispatch = middleware(this.dispatch.bind(this));

        return this;
    }

    /** register a store with the global Dispatcher, optionally pass in a new name (by default this comes from the object passed in) */
    registerStore(store: Store<any>, name: string = (<typeof Store>store.constructor).namespace): Store<any> {
        if (has(this.stores, name) && !module.hot) {
            console.warn(`A store instance has already been registered with this name '${name}', you are replacing it.`);
        }

        let state = store.initialState;

        if (this.persisted) {
            let loadedState = this.loadCache(name);

            if (loadedState) {
                state = assign({}, state, store.deserialize(loadedState));
            }
        }

        this.state = <{ [key: string]: any }>Immutable(assign({}, { [name]: state }, this.state));

        return this.stores[name] = store;
    }

    /** registers a callback returns a function to unsubscribe */
    subscribe(handler: Function): Function {
        if (!lodashContains(this.subscriptions, handler)) this.subscriptions.push(handler);

        return handler;
    }

    unsubscribe(handler: Function) {
        this.subscriptions = without(this.subscriptions, handler);
    }

    private holding: boolean = false;
    hold() { this.holding = true; }
    release() { this.holding = false; this._broadcastChange(); }

    /** broadcasts state to subscribed handlers. */
    _broadcastChange = () => {
        if (this.holding) return;
        this.subscriptions.forEach(handler => handler());
    }

    // dispatch is not the usual suspect here. Instead
    // this runs through the stores' "reducers" and puts state
    // _onto the dispatcher_ here. TODO: Rename this to better reflect
    // the non-flux pattern it performs, and consider renaming the dispatcher
    // to store (like redux) and the existing "stores" to collections of reducers.
    dispatch<T>(...actions: ActionSignature<T>[]) {
        let shouldBroadcast: boolean = false;

        each(actions, action => {
            this.state = this.reducer(this.state, action);

            if (!action.meta || !action.meta.silent) shouldBroadcast = true;
        });

        if (shouldBroadcast) this._broadcastChange();
    }

    // TODO consider naming here, even if just to "reduce"
    reducer(state: any, action: ActionSignature<any>) {
        return state;
        // return Immutable(assign({}, state, mapValues(this.stores, (store, key) => {
        //     return store.reducer(state ? state[key] : void 0, action)
        // })));
    }

    // serializes all stores into one object reflecting getState structure
    // used internally only for persistence
    _serialize() {
        let state = this.getState();

        return mapValues(this.stores, (store, key) => store.serialize(state ? state[key] : void 0));
    }

    private persisted: boolean = false;
    private loadCache: (namespace: string) => Object;
    enablePersistence(save: (state: Object) => void, load: (namespace: string) => Object) {
        if (this.persisted) throw new Error('persistence is already enabled');
        this.persisted = true;

        this.subscribe(() => save(this._serialize()));
        this.loadCache = load;
    }
}


export const dispatcher = new Dispatcher();
export const dispatch = <T>(...actions: ActionSignature<T>[]) => dispatcher.dispatch.call(dispatcher, ...actions);
export default dispatcher;
