/// <reference path='./index.d.ts' />

import { ActionHandlers, ActionSignature, Store } from '../../../lib/pork/index';
import { omit, mapValues, has, without, defaultsDeep, clone } from 'lodash';

import { processInterview } from './util';

import { FindingStore } from '../finding';

type State = Interviews;

// TODO: rename the folder scheme here so it aligns with the name of the store
// TODO: change instances of "load" in actions to "fetch", what they're actually doing.

export class InterviewStore extends Store<State> {
    static namespace = 'interviews';

    initialState: State = {
        isFetchingExports: false
    }

    deserialize(state: Object): State {
        return <State>mapValues(omit(state, 'placeholder'), (interview: Object) => processInterview(interview));
    }

    actionHandlers: ActionHandlers = {
        clearPrivateData: (state: State, action: ActionSignature<ClearPrivateDataPayload>) => this.initialState,

        load,

        create, change, update, remove,

        updateVersionCache,

        transcribeInterview, createNotes,

        loadNotes, loadTranscripts,

        updateRecording, finishRecording, finishedUploading,

        // createFinding, deleteFinding
        [`${FindingStore.namespace}.create`]: addFindingToInterview,
        [`${FindingStore.namespace}.remove`]: removeFindingFromInterview
    }
}

/** replaces entire state */
function load(state: State, action: ActionSignature<State>) {
    if (action.error) return state;

    return action.payload;
}

// create interview
function create(state: State, action: ActionSignature<Interview>): State {
    state = <State>omit(state, 'placeholder');

    if (action.error) return state;

    return reduceInterview(state, action.payload);
}

// completely reassign an interview, including creating one on state if it doesn't already exist
function change(state: State, action: ActionSignature<Interview>): State {
    if (action.error) return state;

    const interview = action.payload;

    return Object.assign({}, state, { [interview.id]: interview });
}

function updateVersionCache(state: State, action: ActionSignature<any>): State {
    const {
        interviewID,
        versionCache
    } = action.payload;

    const interview = state[interviewID];
    let   notes     = clone(interview.notes);
    notes.versionCache = versionCache;

    // Alert our service worker to the change
    // navigator.serviceWorker.controller.postMessage({
    //     cmd: 'update_hash',
    //     interviewID,
    //     hash: versionCache
    // });

    const interviewWithNotes = Object.assign({}, interview, { notes });

    return reduceInterview(state, interviewWithNotes);
}

// update interview
function update(state: State, action: ActionSignature<Interview>): State {
    if (action.error) return state;

    return reduceInterview(state, action.payload);
}

// remove interview
function remove(state: State, action: ActionSignature<string>): State {
    if (action.error) return state;

    return <State>omit(state, action.payload);
}

function transcribeInterview(state: State, action: ActionSignature<{interviewID: string, transcript: api.TranscriptArtifact}>) {
    if (action.error) return state;

    let interview: Interview = state[action.payload.interviewID];
    let transcripts:api.TranscriptArtifact[] = [];

    if (interview.transcripts && interview.transcripts.length) {
        transcripts = transcripts.concat(interview.transcripts);
    }

    transcripts.push(action.payload.transcript);

    return reduceInterview(state, Object.assign({}, interview, { transcripts }));
}

// create notes artifact on interview
function createNotes(state: State, action: ActionSignature<api.NotesArtifact>): State {
    if (action.error) return state;

    const interview: Interview
        = state[action.payload.interviewID];

    const notes: api.NotesArtifact
        = action.payload.notes; // TODO: work out typing here

    const interviewWithNotes: Interview
        = Object.assign({}, interview, { notes });

    return reduceInterview(state, interviewWithNotes);
}

// Both create and load actions use this handler.. may need to change??
function loadNotes(state: State, action: ActionSignature<api.NotesArtifact>): State {
    if (action.error) return state;

    const interview: Interview
        = state[action.payload.interviewID];

    const notes: api.NotesArtifact
        = action.payload.notes; // TODO: work out typing here

    const interviewWithNotes: Interview
        = Object.assign({}, interview, { notes });

    return reduceInterview(state, interviewWithNotes);
}

function loadTranscripts(state: State, action: ActionSignature<api.TranscriptResponse>): State {
    if (action.error) return state;

    const interview: Interview
        = state[action.payload.interviewID]; // TODO type this out right

    const transcripts: api.TranscriptResponse
        = action.payload.transcripts

    const interviewWithTranscripts: Interview
        = Object.assign({}, interview, { transcripts });

    return reduceInterview(state, interviewWithTranscripts);
}

// TODO: This is getting called when a user clicks in the artifact editor. Address that.
function addFindingToInterview(state: State, action: ActionSignature<Finding>) {
    if (action.error) return state;

    // TODO: may not be necessary once artifact editor click doesn't call this
    if (!action.payload.interviewID) return state;

    const interviewID           = action.payload.interviewID;
    const findingID             = action.payload.id;
    const interview             = state[interviewID];
    const findings              = [findingID].concat(interview.findings);
    const interviewWithFindings = Object.assign({}, interview, { findings });

    return reduceInterview(state, interviewWithFindings);
}

function removeFindingFromInterview(state: State, action: ActionSignature<Finding>) {
    if (action.error) return state;

    const interviewID             = action.payload.interviewID;
    const findingID               = action.payload.id;
    const interview               = state[interviewID];
    const interviewWithoutFinding = Object.assign({}, interview, { findings: without(interview.findings, findingID) })

    return reduceInterview(state, interviewWithoutFinding);
}

function updateRecording(state: State, action: ActionSignature<InterviewReference>): State {
    if (action.error) return state;

    const interview: Interview       = state[action.payload.id];
    const recordingPart: number      = interview.recordingPart ? interview.recordingPart + 1 : 1;
    const interviewWithRecordingPart = Object.assign({}, interview, recordingPart);

    return reduceInterview(state, interviewWithRecordingPart);
}

function finishedUploading(state: State, action: ActionSignature<string>) {
    // The payload is our interviewID
    const interviewID = action.payload;

    const interview: Interview = state[interviewID];
    const interviewWithFinishedUploading = Object.assign({}, interview, { hasUploaded: true });
    
    return reduceInterview(state, interviewWithFinishedUploading);
}

function finishRecording(state: State, action: ActionSignature<AudioArtifactPayload>): State {
    if (action.error) return state;

    const { audio, id } = action.payload;
    const interview: Interview = state[id];
    const interviewWithFinishedRecording = Object.assign({}, interview, { audio, recordingPart: undefined })

    return reduceInterview(state, interviewWithFinishedRecording);
}

// reducer: modify an existing interview in store state
function reduceInterview(state: State, interview: Interview | InterviewReference): State {
    return Object.assign({}, state, { [interview.id]: interview });
}
