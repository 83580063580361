import { ActionSignature } from '../../lib/pork';
import { assign, has } from 'lodash';

// Modify created/modified date strings to JS date
export function processMetadata<T extends api.Metadata>(object: T): T & StoreMetadata {
    let transform: StoreMetadata = { created: new Date(object.created) };

    if (has(object, 'modified')) transform.modified = new Date(object.modified);

    return <T & StoreMetadata>assign({}, object, transform);
}

export function constructAction<T>(type: string, payload?: T, error: boolean = false): ActionSignature<T> {
    return {
        type: type,
        payload, error
    };
}

// more thorough capitalizeFirst letter conditions
export function capitalizeFirstLetter(input: string) {
    if (input === undefined || input === null || input.length === 0) { return input; }

    if (input.length >= 1) {
        const firstChar = input.charAt(0);
        const s = input.slice(1);

        if (firstChar === undefined || firstChar === null || firstChar === "") {
            return firstChar.toUpperCase() + s;
        }
        else {
            return input;
        }
    }
    else {
        return input;
    }
}

export const redirectToMaintenanceErrorPage = () => {
    const baseURL = window.location.origin;
    const maintenancePageUrl = `${baseURL}/maintenance-error`;

    window.location.href = maintenancePageUrl;
}
