/// <reference path='./index.d.ts' />
/// <reference path='../finding/index.d.ts' />

import { map, indexBy, findWhere } from 'lodash';
import { processMetadata } from '../util';

export function processInterview(interview: api.Interview) {

    // interview with created/modified date string cast to JS Date object
    let processedInterview = processMetadata<api.Interview, Interview>(interview);

    if (interview.findings && interview.findings.length && typeof interview.findings[0] !== 'string')
        processedInterview.findings = map(interview.findings, finding => finding.id);

    if (processedInterview.audio) {
        processedInterview.audio = processMetadata<api.AudioArtifact, AudioArtifact>(interview.audio);
    }

    return processedInterview;
}
